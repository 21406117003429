import UserBrowserCharMap from "./user_browser_char_map";
import DataTableFilter from './data_table_filter';

class DataTableController {
  constructor(tableElement, columns, emptyTableDefinition, dtOptions, filters_content) {
    if (window.data_table_controller && data_table_controller.datatable_obj instanceof $.fn.dataTable.Api) {
      data_table_controller.datatable_obj.destroy();
    }
    this.emptyTableDefinition = emptyTableDefinition;
    this.dtOptions = dtOptions || {};
    this.tableElement = tableElement;
    this.dtFilter = new DataTableFilter(filters_content, columns);
    this.columns = columns;
    this.datatable_obj = null;
    if (!this.dtOptions.lazy_load) {
      this.build();
    }
    this.state_flags = {
      first_render_header: false
    };
    this.deafult_klass_data = [null, {
      title: '',
      color: null,
      parent_id: null,
      id: null
    }];
    this.pageSize = 10;
  }

  build() {
    if (this.datatable_obj) { return; }

    this.datatable_obj = this.tableElement.DataTable(
        this.build_datatable_settings(this.tableElement)
    );
  }

  pageLength(){
    if (!this.pageSize) {
      let wrapper = document.querySelector('#content-wrapper');
      let header = document.querySelector('#page-header-wrapper');
      let dist = document.querySelector('#table-block-wrapper');

      let margTop = parseInt($(dist).css('margin-top'));
      let padTop = parseInt($(wrapper).css('padding-top'));
      let padBottom = parseInt($(wrapper).css('padding-bottom'));

      let height = innerHeight - dist.offsetHeight - 104 - (padBottom+padTop) - header.offsetHeight - margTop; // расчет высоты, для макс кол-ва колонок в таблице
      let rows_limit = Math.floor(height / 50);
      return rows_limit > 0 ? rows_limit : 1; // 50 - высота колонки в таблице
    }

    return this.pageSize;
  }

  table_footer_render(api) {
    const footer = $('.table-footer-wrapper');
    const info = api.page.info();
    const count_diff = info.recordsDisplay - info.end;
    let count_next_load = 10;
    if (count_diff < count_next_load) {
      count_next_load = count_diff;
    }
    if (!this.state_flags.first_render_footer) {
      footer.append(StaticDataTableMarkupBuilder.load_more_btn_template(count_diff));
      footer.append(StaticDataTableMarkupBuilder.active_filters_list_template());
    }
    this.state_flags.first_render_footer = true;
    this.update_load_more_btn_state(count_diff, count_next_load);
  }

  update_load_more_btn_state(count_diff, count_next_load) {
    let load_more_btn = $('.load-more-btn');
    load_more_btn.find('.load-more-count').text(count_next_load);
    if (count_diff === 0) {
      load_more_btn.hide();
    } else {
      load_more_btn.show();
    }
  }

  buildEmptyPanel() {
    let content = {};
    if (this.emptyTableDefinition.tableDataExist === false) {
      content.image = this.emptyTableDefinition.images.noData;
      content.text = this.emptyTableDefinition.emptyTableText;
    } else {
      content.image = this.emptyTableDefinition.images.filtered;
      content.text = 'Нет данных для отображения, с учётом текущих <nobr>фильтров и периода.</nobr>';
    }

    return `<div class="empty-data"><img height="280" src="${content.image}"><p>${content.text}</p></div>`;
  }

  hasForcedInitialOrder(){
    return this.dtOptions.hasOwnProperty('order');
  }

  loadDataConfig() {
    if (this.dtOptions.hasOwnProperty('clientSideConfig')) {
      if ($.fn.dataTable[this.dtOptions.clientSideConfig.ajaxCustomPlugin]) {
        this.dtOptions.clientSideConfig.ajax = $.fn.dataTable[this.dtOptions.clientSideConfig.ajaxCustomPlugin](
          $.extend({
            "url": $(this.tableElement).attr('data-source'),
            "type": "POST",
            "data": function (params) {
              if (typeof goodok_daterangepicker !== 'undefined') {
                params.dates = goodok_daterangepicker.dates;
              }
              const mapper = new UserBrowserCharMap();
              params.user_chars = mapper.restore();
              params.additional_options = window.location.search.replace('?', '');
  
              return params;
            }
          }, this.dtOptions.clientSideConfig.ajaxCustomPluginOptions || {})
        );
      }
      return this.dtOptions.clientSideConfig;
    } else {
      return {
        "serverSide": true,
        "ajax": $.fn.dataTable.pageLoadMore({
          "url": $(this.tableElement).attr('data-source'),
          "type": "POST",
          "data": function (params) {
            if (typeof goodok_daterangepicker !== 'undefined') {
              params.dates = goodok_daterangepicker.dates;
            }
            const mapper = new UserBrowserCharMap();
            params.user_chars = mapper.restore();
            params.additional_options = window.location.search.replace('?', '');

            return params;
          }
        }),
        "rowCallback": function (row, data) {
          if (data.settings_phone && data.settings_phone.match("data-can-renew")) {
            $(row).addClass('tr_not_reserved_phone');
          }
        }
      }
    }
  }

  build_datatable_settings() {
    let self = this;
    let dt_settings = {
      "stateSave": !this.hasForcedInitialOrder(),
      "processing": true,
      "pagingType": "full_numbers",
      "columns": columns,
      "pageLength": this.pageLength(),
      "language": {
        "searchPanes": {
          "emptyPanes": this.buildEmptyPanel(),
        },
        "zeroRecords": this.buildEmptyPanel(),
        "info": StaticDataTableMarkupBuilder.rows_counter_template('_END_', '_TOTAL_'),
        "infoEmpty": StaticDataTableMarkupBuilder.rows_counter_template(0, 0)
      },
      "headerCallback": function (thead, data, start, end, display) {
        if (typeof(data_table_controller) !== "undefined"){
          data_table_controller.datatable_header_callback(thead, data, start, end, display, this.api());
        }
        self.headerCallback(thead, data);
      },
      "drawCallback": function (dt_callback_settings) {
        DataTableController.datatable_draw_callback(dt_callback_settings, this.api());
      },
      "rowCallback": self.rowCallback,
      "initComplete": function (settings, json) {
        self.tableInitComplete(this.api(), settings);
        if(window.UIHelper !== undefined){
          window.UIHelper.firstShow(location.pathname);
        }
        if (typeof tableDrawCallback == "function") {
            tableDrawCallback();
        }
      },
      "dom": 'r<"table-overflow-wrapper"t><"table-footer-wrapper"i>',
      "searchCols": this.dtFilter.build_search_by_params()
    };

    if ( this.hasForcedInitialOrder() ) {
      dt_settings.order = this.dtOptions.order;
    }

    return {...dt_settings, ...this.loadDataConfig()};
  }

  tableInitComplete(api, settings) {
    let self = this;
    this.tableElement.find('thead th').off('click.DT');
    $.each(columns, function (index, column_settings) {
      let selector = `.filter-wrapper[data-column-name="${column_settings.data}"]`;
      selector += self.dtFilter.hasFilter(column_settings.data);
      api.order.listener(selector, index);
    });
    this.dtFilter.setup_obj_filters_by_dt_search(api);
  }

  headerCallback(thead, data) {}

  rowCallback(row, data) {}

  //это колбек изменения даты, но в двух местах используется для обновления таблицы со сбросом
  change_dates() {
    this.datatable_obj.page.resetMore();
    this.datatable_obj.ajax.reload();
  }

  load_more() {
    this.datatable_obj.page.loadMore(this.pageLength());
  }

  change_view_klass(view, column_index, el_id) {
    // упарвляющий хендлер для кнопок + при подтверждении в new/update
    // рендер с подменой контента дропа
    let column = columns[column_index];
    let filter_settings = this.dtFilter.get_filter_settings_by_index(column_index);
    let $column = this.tableElement.find('thead th').eq(column_index);
    let dropdown_menu = $column.find('.dropdown-menu');
    dropdown_menu.removeAttr('data-klass-change-id');
    let filter_html;
    if (view == 'edit') {
      // на визуал редактирования списка
      filter_html = StaticDataTableMarkupBuilder.build_klass_filter_edit_template(filter_settings);
    } else if (view == 'select') {
      // на первичный визуал выбора фильтра
      filter_html = StaticDataTableMarkupBuilder.build_klass_filter_template(filter_settings, false);
    } else if (view == 'new/update') {
      // на визуал изменения названия/родителя/цвета класса
      // NOTE: возможно лучше будет сделать запрос на партиал формы
      let el_data = $.extend(true, [], this.deafult_klass_data);
      if (el_id) {
        el_data = filter_settings.content.find(function (v) { return v[0] == el_id; });
      }
      el_data[1].parent_data = $.extend(true, [], this.deafult_klass_data);
      if (el_data[1].parent_id) {
        el_data[1].parent_data = filter_settings.content.find(function (v) { return v[0] == el_data[1].parent_id; });
      }
      filter_html = StaticDataTableMarkupBuilder.build_klass_filter_new_update_template(filter_settings, el_data);
      dropdown_menu.attr('data-klass-change-id', String(el_data[0]));
    }

    if (filter_html) {
      let dropdown_menu_inner_wrapper = $column.find('.dropdown-menu-inner-wrapper');
      dropdown_menu_inner_wrapper.html(filter_html);
      if (view === 'select') {
        this.datatable_obj.order.listener(`thead th:eq(${column_index}) .sort-filter-btn`, column_index);
        this.dtFilter.cancel_filter(column_index);
      }
      dropdown_menu.attr('data-view', view);
      center_text_open($column.find('.dropdown'));
    }
  }

  reset_class_view(column_index){
    this.change_view_klass('select', column_index);
  }

  remove_klass(column_index, el_id) {
    let self = this;
    $.ajax({
      type: 'DELETE',
      url: `/account/klasses/${el_id}`,
      beforeSend: function(request) {
        window.throbber.show();
      },
      success: function (response) {
        self.dtFilter.remove_klass_filter(el_id, column_index);
        data_table_controller.change_view_klass('edit', column_index);
        data_table_controller.change_dates();
        window.throbber.hide();
      }
    });
  }

  new_klass(column_index) {
    let self = this;
    let new_data = this.get_new_klass_data(column_index);
    $.ajax({
      type: 'POST',
      url: '/account/klasses',
      data: { klass: new_data },
      dataType: 'json',
      beforeSend: function(request) {
        window.throbber.show();
      },
      success: function (response) {
        self.dtFilter.update_klass_filter(response);
        data_table_controller.change_view_klass('edit', column_index);
        window.throbber.hide();
      },
      error: function (response) {
        error_show(response.responseJSON.title);
        window.throbber.hide();
      }
    });
  }

  edit_klass(column_index, el_id) {
    let self = this;
    let new_data = this.get_new_klass_data(column_index);
    $.ajax({
      type: 'PATCH',
      url: `/account/klasses/${el_id}`,
      data: { klass: new_data },
      dataType: 'json',
      beforeSend: function(request) {
        window.throbber.show();
      },
      success: function (response) {
        self.dtFilter.update_klass_filter(response);
        data_table_controller.change_view_klass('edit', column_index);
        data_table_controller.change_dates();
        window.throbber.hide();
      },
      error: function (response) {
        error_show(response.responseJSON.title);
        window.throbber.hide();
      }
    });
  }

  get_new_klass_data(column_index) {
    let filter_wrapper = this.tableElement.find(`thead th:eq(${column_index})`);
    let current_data = {};
    let data_els = filter_wrapper.find('[data-klass-edit-type]:not(.klass-color-picker-list-el-input), [data-klass-edit-type]:checked');
    for (let input of data_els) {
      let $input = $(input);
      let value_type = $input.attr('data-klass-edit-type');
      let value = $input.val();
      if (value_type === 'parent_id') {
        value = Number(value) || null;
      }
      current_data[value_type] = value;
    }
    return current_data;
  }

  validate_klass_edit_change(column_index) {
    let filter_wrapper = this.tableElement.find(`thead th:eq(${column_index})`);
    let el_id = Number(filter_wrapper.find('.dropdown-menu').attr('data-klass-change-id')) || null;
    let column_name = filter_wrapper.attr('data-column-name');
    let data_before_edit = this.dtFilter.get_filter_settings_by_index(column_index).content.find(function (v) { return v[0] == el_id; });
    let save_btn = filter_wrapper.find('.applay-filter-btn-wrapper');
    if (!data_before_edit) {
      data_before_edit = $.extend(true, [], this.deafult_klass_data);
    }
    let current_data = this.get_new_klass_data(column_index);

    let changed = false;
    $.each(current_data, function (value_type, value) {
      if (data_before_edit[1][value_type] != value) {
        changed = true;
        return false;
      }
    });
    let is_valid_values = current_data.title.length !== 0;

    if (is_valid_values && changed) {
      save_btn.css('display', 'inline-block');
    } else {
      save_btn.hide();
    }
  }

  filterColumn(columnIndex, filterValue) {
    if(filterValue !== ''){
      let seriaLizedFilterValue = filterValue.join('|');
      this.datatable_obj.columns(columnIndex).search(seriaLizedFilterValue).ajax.reload();
  } else 
      this.datatable_obj.columns(columnIndex).search(['']).ajax.reload();
  }
  
  change_temp_filter(column_index, value, state, parent_id){
    this.dtFilter.change_temp_filter(column_index, value, state, parent_id);
  }

  search_in_filter(column_index, value, el, event){
    this.dtFilter.search_in_filter(column_index, value, el, event);
  }

  applay_filter() {
    this.dtFilter.applay_filter();
  }

  remove_filter(column_index){
    this.dtFilter.remove_filter(column_index);
  }

  get_all_child_elements(parent_id, els, column_index){
   return this.dtFilter.get_all_child_elements(parent_id, els, column_index);
  }
  
  static datatable_draw_callback(dt_callback_settings, api) {
    if (typeof(data_table_controller) !== "undefined"){
      data_table_controller.table_footer_render(api);
      data_table_controller.check_present_empty();
    }
  }

  datatable_header_callback(thead, data, start, end, display, api) {
    this.dtFilter.table_header_render(thead);
    if ( window.throbber != undefined ){
      window.throbber.hide();
    }
  }

  fixed_left_dropdown_content(menu, inner) {
    var dropdown_menu = $(menu),
        dropdown_inner = $(inner);

    if (dropdown_inner.css("position") == "fixed") {
      var table_parent = dropdown_menu.closest(".main-page-table-wrapper").length > 0 ? dropdown_menu.closest(".main-page-table-wrapper") : dropdown_menu.closest("#table-block-wrapper"),
          menu_left = dropdown_menu.offset().left,
          menu_width = dropdown_menu.outerWidth(),
          inner_width = dropdown_inner.outerWidth(),
          inner_height = dropdown_inner.outerHeight(),
          inner_top = dropdown_inner.offset().top,
          table_overflow = dropdown_menu.closest(".table-overflow-wrapper"),
          table_scroll_left = table_overflow.scrollLeft(),
          table_left = table_overflow.offset().left,
          table_width = table_overflow.outerWidth();

      var inner_left = menu_left + menu_width / 2 - inner_width / 2 - table_left;

      if (window.innerWidth < 1000) {
        dropdown_inner.attr('style', `left: ${inner_left}px !important`);
      } else {
        dropdown_inner.css('left', inner_left);
      }

      if ( window.innerWidth > 1000 && inner_left < -inner_width / 2 ) {
        dropdown_inner.css('left', - inner_width/ 2);
      }

      if ( table_width < menu_left + menu_width / 2 - table_left) {
        dropdown_inner.css('left', inner_left - menu_width / 2);
      }

      if ( inner_height + inner_top > $(window).height() && window.innerWidth > 1000 ) {
        var table_wrapper = dropdown_menu.closest("#table-block-wrapper");
        table_wrapper.css("margin-bottom", inner_height - table_wrapper.find(".table-footer-wrapper").outerHeight());
      }
    }
  }

  check_present_empty() {
    if ($(".empty-data").length === 0) {
      $(".table-overflow-wrapper.empty_table_wrapper").removeClass("empty_table_wrapper");
    } else {
      $(".dataTables_empty").closest("tr").addClass("empty_tr");
      $(".dataTables_empty").closest(".table-overflow-wrapper").addClass("empty_table_wrapper");
      $(".empty_table_wrapper").scrollLeft(0);
    } 
  }

  static scrollbarTableWidth() {
    var scroll_table = $(".table-overflow-wrapper"),
        documentWidth = parseInt(scroll_table.prop("clientWidth")),
        windowsWidth = parseInt(scroll_table.innerWidth()),
        scrollbarTableWidth = windowsWidth - documentWidth;
    return scrollbarTableWidth;
  }
}

export default DataTableController;