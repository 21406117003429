class UserBrowserCharMap {
    constructor() {
        this.storageKey = 'gd_char_map';
        this.charMap = this.restore();
        this.mapper = null;
    }

    currentCharMap() {
        return this.restore();
    }

    getMapper(){
        if (this.mapper) { return this.mapper }
        const defaultStyle = 'opacity: 0!important; display: inline !important;' +
            'font-size: 1.4rem!important; font-family: OpenSans!important;' +
            'font-weight: 400; font_style: normal';
        let spanHtml = `<span id="charts-mapper" style="${defaultStyle}"></span>`;
        this.mapper = $(spanHtml);
        $('body').append(this.mapper);
        return this.mapper;
    }

    restore(){
        this.charMap = JSON.parse(window.localStorage.getItem(this.storageKey));
        if (!this.charMap) {
            this.generate();
            this.saveCharMap()
        }

        return this.charMap;
    }

    saveCharMap(){
        localStorage.setItem(this.storageKey, JSON.stringify(this.charMap));
    }

    charSize(char) {
        this.getMapper().text(char);
        const charWidth = this.mapper[0].getBoundingClientRect().width.toFixed(2) * 1;
        return charWidth;
    }

    generate(){
        let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёєжзийклмнопрстуфхцчшщъыьэюя1234567890‘?’“!”(%)[#]{@}/&\\<-+÷×=>:;,.*'.split('');
        this.charMap = {};
        const alphabetSize = alphabet.length;
        for (let i = 0; i < alphabetSize; i++) {
            const char = alphabet[i];
            const width = this.charSize(char);
            if (!this.charMap[width]) {
                this.charMap[width] = '';
            }

            this.charMap[width] = this.charMap[width] + char;
        }

        return this.charMap;
    }

}

export default UserBrowserCharMap;
