// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require('datatables.net-bs')(window, $);
require('packs/dataTables.pageLoadMore');
import utility from "../utility";

window.moment = require('moment');
require("../landing/mainpage");
import LandingTableDataset from "../landing/landing_table";
window.LandingTableDataset = LandingTableDataset;
window.LandingEvents = new LandingTableDataset();

import full_text_tip from "../full_text_tip";
import LandingPriceList from "../landing/landing_price";
window.LandingPriceList = new LandingPriceList();

window.sal = require("sal");

import dropdown from "./dropdown";
import landing_map from "../landing/landing_map";
import StaticDataTableMarkupBuilder from "../datatable/static_data_table_markup_builder";
window.StaticDataTableMarkupBuilder = StaticDataTableMarkupBuilder;
import LandingDataTableController from "../datatable/LandingDataTableController";
window.LandingDataTableController = LandingDataTableController;
window.slick = require('slick');

import Audioplayer from "../datatable/ezl_audioplayer";
window.Audioplayer = new Audioplayer();
window.toastr = require('toastr');
toastr.options = {
    "closeButton": true,
    "closeHtml": '<span class="cross"></span>',
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "showDuration": "5000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};
function documentReady() {
    addActiveHeadItem();
    if (typeof initializeDT !== "undefined") {
        initializeDT();
    }
}
import PromoCodeActivator from "../landing/PromoCodeActivator";
window.PromoCodeActivator = PromoCodeActivator;

document.addEventListener("DOMContentLoaded", documentReady);
