import {textToValidHtmlID} from "./MarkupHelpers";

class StaticDataTableMarkupBuilder {
  static rows_counter_template(how_many, from_many) {
    return  '<span class="common-text">Показано: </span>'+
              `<span class="current-count-rows common-text-bold">${how_many}</span>`+
              '<span class="common-text-bold"> из </span>'+
              `<span class="max-count-rows common-text-bold">${from_many}</span>`+
            '</span>';
  };

  static load_more_btn_template(count_diff) {
    return  '<div class="load-more-btn" onclick="data_table_controller.load_more();">'+
              `<span class="common-text-bold">Показать ещё <span class="load-more-count">${count_diff}</span></span>`+
            '</div>';
  };

  static active_filters_list_template() {
    return  '<div class="active-filters-wrapper" id="active-filters">'+
              '<span class="active-filters-label common-text-bold">Включены фильтры:</span>'+
              '<span class="active-filters-list"></span>'+
            '</div>';
  };

  static active_filter_list_el_template(label, column_index) {
    var click_handler = `data_table_controller.remove_filter(${column_index});`;
    return  `<span class="active-filters-list-el" data-column-index="${column_index}" onclick="${click_handler}">`+
              `<span class="active-filters-list-el-text common-text">${label}</span>`+
              `<span class="cross"></span>`+
            '</span>';
  };

  static wrap_filter_content(html, column_name) {
    return  `<div class="filter-wrapper" data-column-name="${column_name}" id="intro-${column_name}">${html}</div>`;
  };

  static build_checkbox_template(id, value, checked, label_content, change_handler) {
    return  '<div class="custom-checkbox-wrapper">'+
        `<input class="custom-checkbox-input" id="${id}" onchange="${change_handler}" type="checkbox" ${checked ? 'checked' : ''}>`+
        `<label class="custom-checkbox-label" for="${id}">`+
        '<span class="custom-checkbox-outer-path">'+
        '<span class="custom-checkbox-inner-path"></span>'+
        '</span>'+
        `${label_content ? label_content : ''}`+
        '</label>'+
        '</div>';
  };

  static build_common_text_template(text) {
    return  '<span class="header-label">'+
              `<span class="common-text" dropdown-center-open-el="true">${text}</span>`+
              '<span class="sorting-triangles"></span>'+
            '</span>';
  };

  static build_checkbox_label_template(el_data, filter_type, visual, column_index) {
    let label_html;
    if (filter_type === 'klass') {
      // NOTE: 37 - это ширина чекбокса, 10 из них отступо от него
      // NOTE: 66 - это ширина кнопок редактирования/удаления с отступами
      let additional_width = {
        'edit': 66,
        'new/update': 0
      };
      var correct_additional_width = additional_width[visual] !== undefined ? additional_width[visual] : 37;
      let margin_left = el_data.parent_id && visual != 'new/update' ? 10 : 0;
      let max_width = `calc(100% - ${correct_additional_width}px - ${margin_left}px)`;
      let style_string = `style="margin-left: ${margin_left}px; max-width: ${max_width};"`;
      label_html =  `<span class="colored-checkbox-label-btn-wrapper" ${style_string}>`+
                      `<span class="colored-checkbox-label-btn common-text" style="background-color: ${el_data.color};">${el_data.title}</span>`+
                    '</span>';
      if (visual == 'edit') {
        label_html += '<span class="klass-el-control-btns-wrapper">'+
                        `<span class="update-klass-btn" data-dropdown-prevent-close="true" onclick="data_table_controller.change_view_klass('new/update', ${column_index}, ${el_data.id});"></span>`+
                        `<spna class="remove-klass-btn" onclick="data_table_controller.remove_klass(${column_index}, ${el_data.id});"></span>`+
                      '</span>';
      }
    } else if (typeof el_data === "string") {
      label_html = `<span class="common-text">${el_data}</span>`;
    } else {
      label_html = `<span class="colored-checkbox-label common-text" style="background-color: ${el_data.color};">${el_data.title}</span>`;
    }
    return label_html;
  };

  static build_dropdown_wraper_template(options, wrap_in_dropdown, dropdown_class) {
    if (wrap_in_dropdown === undefined) { wrap_in_dropdown = true; };
    let merged_options = $.extend(true, {
      dropdown_content: '',
      sort_filter_html: StaticDataTableMarkupBuilder.build_sort_filter_template(),
      drop_filter_btn_html: StaticDataTableMarkupBuilder.build_drop_filter_btn_template(options.column_index),
      applay_filter_btn_html: StaticDataTableMarkupBuilder.build_applay_filter_btn_template(),
      dropdown_toggle_content: StaticDataTableMarkupBuilder.build_common_text_template(options.label)
    }, options)
    let dropdown_menu_inner_wrapper_content = merged_options.sort_filter_html+
                                              merged_options.dropdown_content+
                                              merged_options.drop_filter_btn_html+
                                              merged_options.applay_filter_btn_html;
    if (!wrap_in_dropdown) {
      return dropdown_menu_inner_wrapper_content;
    }
    return  '<div class="dropdown dropdown-fixed-position">'+
              `<div class="dropdown-toggle" data-toggle="dropdown">${merged_options.dropdown_toggle_content}</div>`+
              '<div class="dropdown-menu" id="sort-project">'+
                '<div class="dropdown-menu-inner-wrapper">'+
                  dropdown_menu_inner_wrapper_content+
                '</div>'+
                '<div class="dropdown-menu-triangle"></div>'+
              '</div>'+
              '</div>'+
              `<div class="dropdown-filter-overlay" ${dropdown_class ? 'onclick="data_table_controller.reset_class_view(${options.column_index})"' : ''}></div>`;
  };

  static build_sort_filter_template() {
    return  '<span class="sort-filter-btn">'+
              '<span class="sort-filter-icon"></span>'+
              '<span class="common-text" data-sorting-vector="desc">По возрастанию</span>'+
              '<span class="common-text" data-sorting-vector="asc">По убыванию</span>'+
            '</span>';
  };

  static build_drop_filter_btn_template(column_index) {
    return  `<span class="drop-filter-btn" onclick="data_table_controller.remove_filter(${column_index});">`+
              '<span class="cross"></span>'+
              '<span class="drop-filter-btn-text common-text">Сбросить</span>'+
            '</span>';
  };

  static build_applay_filter_btn_template() {
    return  '<span class="applay-filter-btn-wrapper">'+
              '<span class="applay-filter-btn common-text" onclick="data_table_controller.applay_filter();">Применить</span>'+
            '</span>';
  };

  static build_default_filter_template(filter_settings) {
    let dropdown_content = '';
    let has_content = filter_settings.content.length !== 0;
    if (has_content) {
      if (filter_settings.content.length >= 10) {
        dropdown_content += '<div class="filter-search-wrapper">'+
                              '<span class="custom-input-wrapper">'+
                                `<input class="custom-input common-text" type="text" placeholder="Поиск" onkeyup="data_table_controller.search_in_filter(${filter_settings.column_index}, value, this, event)">`+
                                '<span class="filter-search-icon"></span>'+
                              '</span>'+
                            '</div>';
        dropdown_content += '<div class="filter-no-values-search-wrapper">'+
                              '<span class="filter-no-values-search-text common-text">Ничего не найдено</span>'+
                            '</div>';
      }
      dropdown_content += '<div class="dropdown-list-scroll-wrapper"><ul class="dropdown-list">';

      $.each(filter_settings.content, function(index, el_data){
        if(el_data === null) { return; }
        let filterContentID = `${filter_settings.column_index}_${filter_settings.type}_${el_data[0]}`;
        let id = textToValidHtmlID(filterContentID);
        let checked = false;
        let checkbox_label = StaticDataTableMarkupBuilder.build_checkbox_label_template(el_data[1]);
        let checkbox_change_handler = `data_table_controller.change_temp_filter(${filter_settings.column_index}, '${el_data[0]}', checked);`;
        let checkbox_html = StaticDataTableMarkupBuilder.build_checkbox_template(
            id, el_data[0], checked, checkbox_label, checkbox_change_handler
        );
        dropdown_content += `<li class="filter-el" data-value-in-search="true">${checkbox_html}</li>`;
      });

      dropdown_content += '</ul></div>';
    }

    let options = {
      dropdown_content: dropdown_content,
      label: filter_settings.label,
      column_index: filter_settings.column_index
    };
    if (!has_content) {
      options.applay_filter_btn_html = '';
      options.drop_filter_btn_html = '';
    }
    return StaticDataTableMarkupBuilder.build_dropdown_wraper_template(options);
  };

  static build_klass_filter_template(filter_settings, wrap_in_dropdown) {
    let dropdown_content = '';
    let has_content = filter_settings.content.length !== 0;
    dropdown_content = '<div class="dropdown-list-scroll-wrapper"><ul class="dropdown-list">';
    $.each(filter_settings.content, function(index, el_data){
      if(el_data === null) {return;};
      let filterContentID = `${filter_settings.column_index}_${filter_settings.type}_${el_data[0]}`;
      let id = textToValidHtmlID(filterContentID);
      let checked = false;
      let checkbox_label = StaticDataTableMarkupBuilder.build_checkbox_label_template(el_data[1], filter_settings.type);
      let checkbox_change_handler = `data_table_controller.change_temp_filter(${filter_settings.column_index}, '${el_data[0]}', checked, '${el_data[1].parent_id}');`;
      let checkbox_html = StaticDataTableMarkupBuilder.build_checkbox_template(id, el_data[0], checked, checkbox_label, checkbox_change_handler);
      dropdown_content += `<li class="filter-el">${checkbox_html}</li>`;
    });
    dropdown_content += '</ul></div>';
    dropdown_content += `<div class="start-edit-klass" data-dropdown-prevent-close="true" onclick="data_table_controller.change_view_klass('edit', ${filter_settings.column_index})"><span>Изменить</span></div>`;
    let options = {
      dropdown_content: dropdown_content,
      label: filter_settings.label,
      column_index: filter_settings.column_index,
      drop_filter_btn_html: ''
    };
    if (!has_content) {
      options.applay_filter_btn_html = '';
    }
    return StaticDataTableMarkupBuilder.build_dropdown_wraper_template(options, wrap_in_dropdown, true);
  };

  static build_klass_filter_edit_template(filter_settings) {
    let dropdown_content = '';
    dropdown_content = '<span class="dropdown-menu-header">Редактирование классов</span>';
    dropdown_content += '<div class="dropdown-list-scroll-wrapper"><ul class="dropdown-list">';
    $.each(filter_settings.content, function(index, el_data){
      if (el_data[0] !== 'noklass'){
        let checkbox_label = StaticDataTableMarkupBuilder.build_checkbox_label_template(el_data[1], filter_settings.type, 'edit', filter_settings.column_index);
        dropdown_content += `<li class="filter-el">${checkbox_label}</li>`;
      }
    });  
    dropdown_content += '</ul></div>';
    dropdown_content += `<div class="add-new-klass-wrapper" data-dropdown-prevent-close="true" onclick="data_table_controller.change_view_klass('new/update', ${filter_settings.column_index});">`+
                          '<span class="custom-plus"></span>'+
                          '<span class="common-text">Новый класс</span>'+
                        '</div>';
    dropdown_content += `<div class="cancel-klass-btn" data-dropdown-prevent-close="true" onclick="data_table_controller.change_view_klass('select', ${filter_settings.column_index})">`+
                          '<span class="custom-arrow">'+
                            '<span class="custom-arrow-wrapper">'+
                              '<span class="custom-arrow-path custom-arrow-path-top"></span>'+
                              '<span class="custom-arrow-path custom-arrow-path-bottom"></span>'+
                            '</span>'+
                          '</span>'+
                          '<span class="common-text">Отмена</span>'+
                        '</div>';
    let options = {
      dropdown_content: dropdown_content,
      applay_filter_btn_html: '',
      drop_filter_btn_html: '',
      sort_filter_html: ''
    };
    return StaticDataTableMarkupBuilder.build_dropdown_wraper_template(options, false);
  };

  static build_klass_filter_new_update_template(filter_settings, klass_data) {
    let colors_arr = [
      '#555D7D',
      '#34A88C',
      '#4169E1',
      '#7B68EE',
      '#FF5170',
      '#E8BA14',
      '#71E9C5',
      '#62B4FF',
      '#5848B9',
      '#D33753'
    ];
    let dropdown_content = '';
    dropdown_content = `<span class="dropdown-menu-header">${klass_data[0] ? klass_data[1].title : 'Новый класс'}</span>`;

    let handler_text = `data_table_controller.validate_klass_edit_change(${filter_settings.column_index});`;
    dropdown_content += '<span class="custom-input-wrapper">'+
                          `<input id="klass-name" class="custom-input common-text" data-klass-edit-type="title" oninput="${handler_text}" type="text" placeholder="Название" value="${klass_data[1].title}">`+
                        '</span>';

    let dropdown_list_content = `<li class="filter-el custom-select-el" data-custom-select-value="null" data-custom-select-hidden="${klass_data[1].parent_id === null}"><span class="custom-select-no-value common-text">- Подкласс для</span></li>`;
    let parent_klass_togler = '<span class="custom-select-no-value common-text">- Подкласс для</span>';
    let select_values = `<option ${!klass_data[1].parent_id ? 'selected' : ''} value="null">- Подкласс для</option>`;
    if (klass_data[1].parent_id) {
      parent_klass_togler = StaticDataTableMarkupBuilder.build_checkbox_label_template(klass_data[1].parent_data[1], filter_settings.type, 'new/update');
    }
    let current_klass_has_childs = klass_data[0] !== null && data_table_controller.get_all_child_elements(klass_data[0], filter_settings.content, filter_settings.column_index).length != 0;
    let parens_arr = filter_settings.content.filter(function(v){ return v[1].parent_id == null && v[0] != klass_data[0] }); // В списке могут быть только родители (логика не более 2ух уровне вложенности)
    $.each(parens_arr, function(index, el_data){
      select_values += `<option ${el_data[0] == klass_data[1].parent_id ? 'selected' : ''} value="${el_data[0]}">${el_data[1].title}</option>`;
      let is_hidden = el_data[0] == klass_data[1].parent_data[0] || // Не отображаем текущего родителя
                      current_klass_has_childs; // Не отображаем варианты кроме "удаление родительского" элемента, если элемент имеет детей (логика не более 2ух уровне вложенности)
      let checkbox_label = StaticDataTableMarkupBuilder.build_checkbox_label_template(el_data[1], filter_settings.type, 'new/update');
      dropdown_list_content += `<li class="filter-el custom-select-el" data-custom-select-value="${el_data[0]}" data-custom-select-hidden="${is_hidden}">${checkbox_label}</li>`;
    });
    let handler_select = `data_table_controller.validate_klass_edit_change(${filter_settings.column_index});`;
    dropdown_content += '<div class="filter-dropdown-wrapper">'+
                          '<div class="dropdown custom-select">'+
                            `<select class="custom-select-input" data-klass-edit-type="parent_id" onchange="${handler_select}">`+
                              select_values+
                            '</select>'+
                            '<div class="dropdown-toggle custom-select-toggle" data-toggle="dropdown">'+
                              '<span class="custom-select-toggle-wrapper">'+
                                parent_klass_togler+
                              '</span>'+
                              '<span class="custom-select-toggle-icon"></span>'+
                            '</div>'+
                            '<div class="dropdown-menu custom-select-menu">'+
                              '<div class="dropdown-list-scroll-wrapper">'+
                                '<ul class="dropdown-list custom-select-list">'+
                                  dropdown_list_content+
                                '</ul>'+
                              '</div>'+
                            '</div>'+
                          '</div>'+
                        '</div>';

    let color_picker_content = '';
    $.each(colors_arr, function(index, color){
      let checked = index == 0 ? true : false;
      if (klass_data[1].color) {
        checked = klass_data[1].color == color
      }
      color_picker_content += StaticDataTableMarkupBuilder.build_color_picker_el_template(index, color, checked, filter_settings.column_index);
    });
    dropdown_content += '<div class="klass-color-picker">'+
                          '<ul class="klass-color-picker-list">'+
                            color_picker_content+
                          '</ul>'+
                        '</div>';

    let new_handler = `data_table_controller.new_klass(${filter_settings.column_index});`
    let edit_handler = `data_table_controller.edit_klass(${filter_settings.column_index}, ${klass_data[0]});`;
    dropdown_content += '<span class="applay-filter-btn-wrapper">'+
                          `<span class="applay-filter-btn common-text" onclick="${klass_data[0] ? edit_handler : new_handler}">Сохранить</span>`+
                        '</span>';

    dropdown_content += `<div class="cancel-klass-btn" data-dropdown-prevent-close="true" onclick="data_table_controller.change_view_klass('edit', ${filter_settings.column_index})">`+
                          '<span class="custom-arrow">'+
                            '<span class="custom-arrow-wrapper">'+
                              '<span class="custom-arrow-path custom-arrow-path-top"></span>'+
                              '<span class="custom-arrow-path custom-arrow-path-bottom"></span>'+
                            '</span>'+
                          '</span>'+
                          '<span class="common-text">Отмена</span>'+
                        '</div>';
    let options = {
      dropdown_content: dropdown_content,
      applay_filter_btn_html: '',
      drop_filter_btn_html: '',
      sort_filter_html: ''
    };
    return StaticDataTableMarkupBuilder.build_dropdown_wraper_template(options, false);
  };

  static build_color_picker_el_template(index, color, checked, column_index) {
    let handler = `data_table_controller.validate_klass_edit_change(${column_index});`;
    return  '<li class="klass-color-picker-list-el">'+
              `<input class="klass-color-picker-list-el-input" id="klass_color_${index}" data-klass-edit-type="color" value="${color}" onchange="${handler}" name="klass-color" type="radio" ${checked ? 'checked="true"' : ''}>`+
              `<label class="klass-color-picker-list-el-label" for="klass_color_${index}" style="background-color: ${color};"></label>`+
            '</li>';
  };
}

export default StaticDataTableMarkupBuilder;