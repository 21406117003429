let textTimer  = null;
$( document ).on('mouseenter', '[data-tip]', function(event){
  let full_text = $(this).attr('data-tip');
  if ($('#full-text-tip').length === 0) {
    create_tip_el();
  }
  if(event.target.classList.contains('sidebar-menu-list-el')){
    textTimer = setInterval(() => show_text_help($(this), full_text), 10);
    setTimeout(() => { clearInterval(textTimer); }, 300);
  } else {
    show_text_help($(this), full_text)
  }
});

$( document ).on('mouseleave', '[data-tip]', function(event){
  hide_text_help();
  if(event.target.classList.contains('sidebar-menu-list-el')){
    clearInterval(textTimer);
  }
});

function create_tip_el() {
  $('body').append('<div id="full-text-tip" class="common-text"></div>');
}

window.show_text_help = function(el, text) {
  if(window.innerWidth > 1000 && text.length > 0){
    let tip = $('#full-text-tip');
    tip.html(`<div class="triangle"></div>${text}`);
    tip.show();
    let window_width = $(window).outerWidth();
    let attr = el.attr('data-aligning-help');

    let el_offsets = el.offset();
    let el_params = {
      width: el.outerWidth(),
      height: el.outerHeight()
    }
    let tip_params = {
      width: tip.outerWidth(),
      height: tip.outerHeight()
    }

    let tip_block_top_pos;
    let tip_block_left_pos;
    
    let left_pos_align = attr === 'center' ? +5 : attr === 'dashnum'? 15 : -5;
    let top_pos_align = attr === 'center' || attr === 'dashnum' ? tip.outerHeight() + ((el.outerHeight() - tip.outerHeight()) / 2) : 5;

    if (attr === 'down') {
      left_pos_align = - (el.outerWidth() + 15);
      top_pos_align = el.outerHeight() + tip.outerHeight() - 15;
    } else if (attr === 'wizcenter') {
      left_pos_align = 5;
      top_pos_align = (el.outerHeight() + tip.outerHeight()) / 2;
    }

    if ( window_width - (el_offsets.left + el_params.width + tip_params.width - 5) < 0 ) {
      tip.css("max-width", ( el_offsets.left - 5 ) );

      tip_block_left_pos = el_offsets.left - tip.outerWidth() - left_pos_align;

    } else {
      tip.css("max-width", window_width - el_offsets.left - el_params.width - 5);
      tip_block_left_pos = el_offsets.left + el_params.width + left_pos_align;
    }

    tip_block_top_pos = el_offsets.top - tip_params.height + top_pos_align - $(window).scrollTop();


    let tip_pos = {
      top: tip_block_top_pos,
      left: tip_block_left_pos
    };

    tip.css(tip_pos);
  }
};


window.hide_text_help = function() {
  let tip = $('#full-text-tip');
  tip.hide();
  tip.css("left", "0");
  tip.css("top", "0");
  tip.css("max-width", "auto");
};

$(window).scroll(function() {
  hide_text_help();
});

$(window).resize(function() {
  hide_text_help();
});

export default function () {};