let dropdown_before_open = new CustomEvent("dropdown_before_open");
let dropdown_after_open = new CustomEvent("dropdown_after_open", {bubbles: true});
let dropdown_before_close = new CustomEvent("dropdown_before_close");
let dropdown_after_close = new CustomEvent("dropdown_after_close");

var social_open = false;

$( document ).on('click', '.dropdown-toggle[data-toggle="dropdown"]', function(event){
  var target = $(event.target);
  var dropdown = target.closest('.dropdown');
  if (dropdown.hasClass('open')) {
    close_dropdown(dropdown);
    dropdown.closest("#table-block-wrapper").css("margin-bottom", 0);
  } else {
    open_dropdown(dropdown);
    // FIXME: костыльное решение при вложенных дропах
    target.parents('.dropdown').addClass('open');
  }
});

$( document ).on('click', '.dropdown-shadow', function(event){
  var target = $(event.target);
  var dropdown = target.closest('.dropdown');
  close_dropdown(dropdown);
});

if(window.innerWidth <= 754){
  
  $( document ).on('click', '[data-dropdown="social"]', function(event) {
    let target = $(event.target);
    let dropdown = target.closest('.dropdown');
    social_open = true;
    open_dropdown(dropdown);
    target.parents('.dropdown').addClass('open');
  });

  $( document ).on('click', '#modal-social-overlay', function() {
    social_open = false;
    hover_social_close();
  });

} else {
  $( document ).on('mouseenter', '[data-dropdown="social"]', function(event) {
    let target = $(event.target);
    let dropdown = target.closest('.dropdown');
    social_open = true;
    open_dropdown(dropdown);
    target.parents('.dropdown').addClass('open');
  });
  
  $( document ).on('mouseleave', '[data-dropdown="social"]', function() {
    social_open = false;
    setTimeout(function() { hover_social_close() }, 100);
  });
}

function hover_social_close(){
  if (social_open == false){
    let dropdown = $('[data-dropdown="social"]').closest(".dropdown");
    close_dropdown(dropdown);
  }
}

window.open_dropdown = function(dropdown){
  dropdown[0].dispatchEvent(dropdown_before_open);
  $('.dropdown:not(#sidebar-menu-wrapper)').removeClass('open');
  dropdown.addClass('open');
  offsetOpenDropDown(dropdown);
  center_text_open(dropdown);
  dropdown[0].dispatchEvent(dropdown_after_open);
  
  $(".table-overflow-wrapper").scroll(function(event) {
    var target = $(event.target);
    var dropdown = target.find('.dropdown.open');
    dropdown.hasClass("open") ? dropdown.closest("#table-block-wrapper").css("margin-bottom", 0) : "";
    dropdown.removeClass('open');
  });

  save_state_menu(dropdown);
};

window.offsetOpenDropDown = function(dropDown) {
  const widthWindow = window.innerWidth;

  const rectDropDown = dropDown[0].getBoundingClientRect(),
    dropDownMenu = dropDown.find(".dropdown-menu"),
    rectDropDownMenu = dropDownMenu[0].getBoundingClientRect();

  if (!dropDownMenu.data("need-offset")) return;

  if (widthWindow <= 1680) {
    dropDownMenu.css({ bottom: "unset", top: "auto" });
    return;
  }

  const targetPositionDropDown = {
      top: window.scrollY + rectDropDown.top,
      bottom: window.scrollY + rectDropDown.bottom,
    },
    windowPosition = {
      top: window.scrollY,
      bottom: window.scrollY + document.documentElement.clientHeight,
    };

  const totalOffsetDropDown = targetPositionDropDown.bottom + rectDropDownMenu.height;

  if (windowPosition.bottom - totalOffsetDropDown < 8) {
    dropDownMenu.css({ bottom: "calc(100% + 10px)", top: "unset" });
  } else {
    dropDownMenu.css({ bottom: "unset", top: "calc(100% + 10px)" });
  }
};

window.close_dropdown = function(dropdown){
  if (!dropdown) { dropdown = $('.dropdown') }
  dropdown[0].dispatchEvent(dropdown_before_close);
  dropdown.hasClass("open") ? dropdown.closest("#table-block-wrapper").css("margin-bottom", 0) : "";
  dropdown.removeClass('open');
  dropdown[0].dispatchEvent(dropdown_after_close);

  save_state_menu(dropdown);
};

function save_state_menu(dropdown) {
  const menuElementID = "sidebar-menu-wrapper";
  if (dropdown.attr('id') !== menuElementID) return;
  if (typeof MenuStateChooser !== 'undefined') {
    MenuStateChooser.saveState($(`#${menuElementID}`));
  }
}

window.center_text_open = function(dropdown) {
  var dropdown_toggler = dropdown.find('.dropdown-toggle');
  var center_el = dropdown_toggler.find('[dropdown-center-open-el="true"]');
  var dropdown_menu = dropdown.find('.dropdown-menu').first();
  if (center_el.length != 0) {
    var diff_left_pos = center_el.offset().left - dropdown_toggler.offset().left;
    var left_pos = diff_left_pos + (center_el.outerWidth() / 2 - dropdown_menu.outerWidth() / 2);
    dropdown_menu.css('left', left_pos);
  }
  if (dropdown_menu.closest(".table-overflow-wrapper").length > 0) { 
    data_table_controller.fixed_left_dropdown_content(center_el[0] || dropdown_toggler[0], dropdown_menu[0]);
  }
  if (dropdown.hasClass("dropdown-numsettings") && window.innerWidth > 1000) {
    if (dropdown.hasClass("dropdown-number-projects")) {
      var left_pos_numset = "auto";
      var dd_width = dropdown.width();
      dropdown.children(".dropdown-menu").attr("style", "width:" + dd_width + "px !important");
    } else {
      var left_pos_numset = dropdown_toggler.offset().left - dropdown_menu.outerWidth() + 30;
    }
    dropdown_menu.css("left", left_pos_numset);
    if (dropdown_toggler.offset().top + dropdown_toggler.outerHeight() + dropdown_menu.outerHeight() + 35 > $(document).scrollTop() + window.innerHeight) {
      var top_pos_numset = dropdown_toggler.offset().top - dropdown_toggler.outerHeight() - dropdown_menu.outerHeight() - 20;
      console.log(top_pos_numset)
      console.log(dropdown_menu)
      dropdown_menu.css({
        "top": top_pos_numset
      });
      dropdown_menu.addClass("lower-dropdown-menu");
    } else {
      dropdown_menu.removeClass("lower-dropdown-menu");
      dropdown_menu.css("top", "auto");
    }
  }
};

$( window ).on('click', function(event){
  let target = $(event.target);
  let parent_with_attr = target.closest('[data-dropdown-prevent-close="true"]');
  let is_close_prevented = target.attr('data-dropdown-prevent-close') == 'true' || parent_with_attr.length != 0;

  if (!is_close_prevented) {
    for (let dropdown of $('.dropdown')) {
      if ($(dropdown).attr('data-small-area-close') == 'true') {
        return;
      }
      if ($(dropdown)[0].id === "sidebar-menu-wrapper" && window.innerWidth >= 760) {
        continue;
      }
      if ($(dropdown).has(event.target).length === 0 && !$(dropdown).is(event.target)) {
        // TODO: не генерит события закрытия, возможно дело в баблинге
        close_dropdown($(dropdown));
      }
    }
  }
});

const templateEmptySearch = () => `
  <li class="filter-el custom-select-el filter-el__search-not-found">Ничего не найдено</li>
`;

$(document).on("click", ".dropdown .dropdown-list .filter-el:not(.filter-el__search-not-found)", (event) => {
  const target = $(event.target),
    dropdown = target.closest(".dropdown"),
    itemsDropDown = dropdown.find(".dropdown-list .filter-el:not(.filter-el__search-not-found)");

  target[0].innerHTML = target[0].innerHTML.replace(/<b>|<\/b>/g, "");
  dropdown.find(".dropdown-search input.dropdown-search__field").val("");
  itemsDropDown.removeClass("hide");
});

$(document).on("input", ".dropdown .dropdown-search input.dropdown-search__field", (event) => {
  const target = $(event.target),
    dropdown = target.closest(".dropdown"),
    dropdownMenuList = dropdown.find(".dropdown-list"),
    itemsDropDown = dropdown.find(".dropdown-list .filter-el:not(.filter-el__search-not-found)"),
    searchVal = target
      .val()
      .toLocaleLowerCase()
      .trim();

  const findItemsDropDown = Array.from(itemsDropDown).filter((item) => {
    item.innerHTML = item.innerHTML.replace(/<b>|<\/b>/g, "");
    if (!searchVal) {
      itemsDropDown.removeClass("hide");
      return true;
    }

    const indexFirstLetter = item.innerHTML.toLocaleLowerCase().indexOf(searchVal);

    if (indexFirstLetter === -1) {
      $(item).addClass("hide");
      return false;
    }

    const lengthWordReplace = indexFirstLetter + searchVal.length;

    const startText = item.innerHTML.slice(0, indexFirstLetter);
    const wordReplace = item.innerHTML.slice(indexFirstLetter, lengthWordReplace);
    const endText = item.innerHTML.slice(lengthWordReplace, item.innerHTML.length);

    $(item).removeClass("hide");
    item.innerHTML = `${startText}<b>${wordReplace}</b>${endText}`;
    return true;
  });

  dropdownMenuList.find(".filter-el__search-not-found").remove();

  if (findItemsDropDown.length) return;

  dropdownMenuList.get(0).insertAdjacentHTML("beforeend", templateEmptySearch());
});

export default function () {};