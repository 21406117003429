import {textToValidHtmlID} from "./MarkupHelpers";

class DataTableFilter {
  constructor(filters, columns){
    this.filters = filters;
    this.temp_filter = {
      column_index: null,
      value: null
    };
    this.state_flags = {
      first_render_header: false
    };
    this.columns = columns;
    this.cntrlIsPressed = false;
    let meta_tag = document.querySelector('[name="current_user"]');
    if (meta_tag) this.currenUser = meta_tag.dataset.id;
  }

  table_header_render(thead) {
    if (this.state_flags.first_render_header) { return; }

    this.state_flags.first_render_header = true;
    var headers_arr = $(thead).find('th');
    let self = this;
    $.each(headers_arr, function (index, header) {
      var column_name = self.columns[index].data;
      var filter_settings = self.filters[column_name] || { content: [] };
      var filter_type = filter_settings.type;
      var filter_html;

      filter_settings.label = self.columns[index].title;
      filter_settings.column_index = index;
      if (filter_type) {
        filter_settings = self[`format_${filter_type}_filter_data`](filter_settings);
        filter_html = StaticDataTableMarkupBuilder[`build_${filter_type}_filter_template`](filter_settings);
      } else {
        filter_html = StaticDataTableMarkupBuilder.build_common_text_template(filter_settings.label);
      }
      filter_html = StaticDataTableMarkupBuilder.wrap_filter_content(filter_html, column_name);
      var th = $(thead).find('th').eq(index);
      th.html(filter_html);
      if (filter_settings.filter_load) $(th).attr('filter_load', 'true');
      th.find('.filter-wrapper > .dropdown').on('dropdown_before_open', function (e) {
        self.cancel_filter(index);
        self.clear_search(index);
        let filter_th = $(e.target).parents('th');
        if (filter_th.attr('filter_load') && !filter_th.attr('data-loaded')){
          let filter_name = filter_th.find('.filter-wrapper').attr('data-column-name');
          filter_th.attr('data-loaded', 'true');

          $.ajax({
            url: window.datatable_filters_path + filter_name,
            data: { dates: goodok_daterangepicker.dates },
            success: function(result){
              var filter_settings = self.filters[column_name];
              self.filters[column_name].content = result;
              filter_settings = self[`format_${filter_settings.type}_filter_data`](filter_settings);
              filter_html = StaticDataTableMarkupBuilder[`build_${filter_settings.type}_filter_template`](filter_settings);
              filter_html = StaticDataTableMarkupBuilder.wrap_filter_content(filter_html, column_name);
              filter_th.find('.dropdown-menu-inner-wrapper').html($(filter_html).find('.dropdown-menu-inner-wrapper').html());
            }
          })
        }
      });

      const column_width = self.columns[index].width;
      if (column_width) {
        th.css({
          'width': column_width,
          'min-width': self.columns[index].min_width || column_width,
          'max-width': self.columns[index].max_width || column_width
        });
      }
    });
  }

  hasFilter(data){
    let selector = '';
    if (this.filters[data]) {
      selector = ' .sort-filter-btn';
    }
    return selector;
  }

  format_default_filter_data(filter_settings) {
    // NOTE: если потребуется какая либо постобработка данных дефолтных фильтров
    return filter_settings;
  }

  format_klass_filter_data(filter_settings) {
    $.each(filter_settings.content, function (index, content) {
      content[1].id = content[0];
    });
    filter_settings.content = this.sort_filter_with_parents(filter_settings.content);

    return filter_settings;
  }

  sort_filter_with_parents(els) {
    let result = [];
    let parents_arr = els.filter(function (v) { return !v[1].parent_id; });
    let self = this;
    $.each(parents_arr, function (i_p, el_p) {
      result.push(el_p);
      let childs_arr = self.get_all_child_elements(el_p[1].id, els);
      $.each(childs_arr, function (i_c, el_c) {
        result.push(el_c);
      });
    });

    return result;
  }

  get_filters_from_ls() {
    return JSON.parse(localStorage.getItem(`goodok_table_filters-${this.currenUser}`));
  }

  setup_obj_filters_by_dt_search(dt_api) {
    let dt_search = dt_api.columns().search();
    let self = this;
    $.each(this.columns, function (i, column_data) {
      if (self.filters[column_data.data] && dt_search[i].length !== 0) {
        self.setup_filter(i, self.deserialize_filter_value(dt_search[i]), false);
      }
    });
  }

  setup_filter(column_index, value, reload) {
    this.filters[column_index] = $.extend([], value);
    this.update_filter_visual(column_index, false);
    this.control_acrive_filters_blocks(column_index);
    if (reload) {
      change_dates();
    }
  }

  deserialize_filter_value(string_value) {
    return string_value.split('|');
  }

  change_temp_filter(column_index, value, state, parent_id) {
    // инициализируем временной фильтр, если он пуст (нет индекса колонки)
    if (this.temp_filter.column_index === null) {
      this.temp_filter.column_index = column_index;
      this.temp_filter.value = [];
      if (this.filters[column_index]) {
        this.temp_filter.value = $.extend([], this.filters[column_index]);
      }
    }
    // добавляем/удаляем значение временного (не применённого) фильтра
    const el_index = this.temp_filter.value.indexOf(value);
    if (state) {
      if (el_index === -1) {
        this.temp_filter.value.push(value);
      }
    } else {
      if (el_index !== -1) {
        this.temp_filter.value.splice(el_index, 1);
      }
    }
    // проверяем состояние родителя по наличию всех дочерних или отсутствию хотя бы одного
    if (parent_id && parent_id != 'null') {
      this.check_parent_el(column_index, parent_id);
    }
    // чекаем/анчекаем все дочерние элементы если изменяют родительский элементы
    if (parent_id == 'null') {
      this.checl_childs_els(column_index, value, state);
    }
    // обновляем визуал (необходимо при программном изменении фильтров)
    this.update_filter_visual(column_index, true);
    // при наличии разницы между временным фильтром и текущим отображаем/скрываем кнопку применения
    var clear_btn = $(`.dataTable thead tr th:eq(${column_index}) .drop-filter-btn`);
    var applay_btn = $(`.dataTable thead tr th:eq(${column_index}) .applay-filter-btn-wrapper`);
    if(this.compare_arrays(this.temp_filter.value, this.filters[column_index])){
      clear_btn.css('display', 'none');
    }
    if (!this.compare_arrays(this.temp_filter.value, this.filters[column_index] || [])) {
      applay_btn.css('display', 'inline-block');
      clear_btn.css('display', 'none');
    } else {
      applay_btn.css('display', 'none');
      clear_btn.css('display', 'flex');
    }
  }

  check_parent_el(column_index, parent_id) {
    let all_include = true;
    let child_elements_ids = this.get_all_child_elements(parent_id, null, column_index)
        .map(function (v) { return v[0]; });
    for (let child_id of child_elements_ids) {
      if (!this.temp_filter.value.includes(String(child_id))) {
        all_include = false;
        break;
      }
    }
    this.change_temp_filter(column_index, parent_id, all_include);
  }

  checl_childs_els(column_index, parent_id, state) {
    let child_elements_ids = this.get_all_child_elements(parent_id, null, column_index)
        .map(function (v) { return v[0]; });
    for (let child_id of child_elements_ids) {
      // изменяем состояние дочернего элемента НЕ передавай parent_id (иначе получится бесконечный луп)
      this.change_temp_filter(column_index, String(child_id), state);
    }
  }

  update_filter_visual(column_index, by_temp) {
    let ids_arr = (by_temp ? this.temp_filter.value : this.filters[column_index]) || [];
    let filter_settings = this.get_filter_settings_by_index(column_index);
    if (!filter_settings) { return; }
    $(`.filter-wrapper[data-column-name="${filter_settings.column_name}"] .custom-checkbox-input`).prop('checked', false);

    for (let id of ids_arr) {
      let htmlID = textToValidHtmlID(`${column_index}_${filter_settings.type}_${id}`);
      $(`#${htmlID}`).prop('checked', true);
    }
  }

  get_all_child_elements(parent_id, els, column_index) {
    if (!els) {
      els = this.get_filter_settings_by_index(column_index).content;
    }
    return els.filter(function (v) { return v[1].parent_id === parent_id; });
  }

  compare_arrays(arr_a, arr_b) {
    if (!arr_a || !arr_b || arr_a.length !== arr_b.length) {
      return false;
    }

    return arr_a.every((e) => arr_b.includes(e)) && arr_b.every((e) => arr_a.includes(e));
  }

  applay_filter(setLsFilter=true) {
    var column_index = this.temp_filter.column_index;
    var filter_settings = this.get_filter_settings_by_index(column_index);
    this.filters[column_index] = $.extend([], this.temp_filter.value);

    let filterValue = '';
    if ((this.filters[column_index].length === 1 && filter_settings.content.length === 1) || (this.filters[column_index].length !== filter_settings.content.length)) {
      filterValue = this.filters[column_index];
    }
    data_table_controller.filterColumn(column_index, filterValue);
    this.control_acrive_filters_blocks(column_index);
    this.clear_temp_filter(column_index);
    if(setLsFilter){
      this.add_ls_filter(column_index, filterValue);
    } else {
      this.remove_ls_filter(column_index);
    }
  }
  
  add_ls_filter(column_index, filterValue){
    let ls_filter = this.get_filters_from_ls() || {};
    let seriaLizedFilterValue = filterValue.join('|');
    const column = columns[column_index].data;
    ls_filter[column] = seriaLizedFilterValue;
    localStorage.setItem(`goodok_table_filters-${this.currenUser}`, JSON.stringify(ls_filter));
  }

  remove_ls_filter(column_index){
    let ls_filter = this.get_filters_from_ls();
    delete ls_filter[columns[column_index].data];
    localStorage.removeItem(`goodok_table_filters-${this.currenUser}`);
    localStorage.setItem(`goodok_table_filters-${this.currenUser}`, JSON.stringify(ls_filter));
  }

  control_acrive_filters_blocks(column_index) {
    let filter_settings = this.get_filter_settings_by_index(column_index);
    var filter_wrapper = $(`.dataTable thead tr th:eq(${column_index}) .filter-wrapper`);
    filter_wrapper.attr('data-active-filter', this.filters[column_index].length !== 0);

    filter_wrapper.find('.dropdown').removeClass('open');

    var drop_filter_btn = filter_wrapper.find('.drop-filter-btn');
    drop_filter_btn.css('display', 'none');
    if (this.filters[column_index].length !== 0) {
      drop_filter_btn.css('display', 'flex');
      if ($(`.active-filters-list-el[data-column-index="${column_index}"]`).length === 0) {
        $('.active-filters-list').append(StaticDataTableMarkupBuilder.active_filter_list_el_template(filter_settings.label, column_index));
      }
    } else {
      $(`.active-filters-list .active-filters-list-el[data-column-index="${column_index}"]`).remove();
    }

    var active_filters = $('.active-filters-wrapper');
    if ($('.active-filters-list-el').length === 0) {
      active_filters.css('display', 'none');
    } else {
      active_filters.css('display', 'flex');
    }
  }

  get_filter_settings_by_index(column_index) {
    let entry = Object.entries(this.filters).find(function (v) {
      return v[1].column_index === column_index;
    });
    if (!entry) { return entry; }
    entry[1].column_name = entry[0];
    entry = $.extend({}, entry[1]);
    return entry;
  }

  clear_temp_filter() {
    this.temp_filter = {
      column_index: null,
      value: null
    };
    $('.applay-filter-btn-wrapper').css('display', 'none');
  }

  remove_filter(column_index) {
    this.temp_filter = {
      column_index: column_index,
      value: []
    };
    var filter_inputs = $(`.dataTable thead tr th:eq(${column_index}) .custom-checkbox-input`);
    filter_inputs.prop('checked', false);
    this.remove_ls_filter(column_index);
    this.applay_filter(false);
  }

  cancel_filter(column_index) {
    if (typeof column_index === 'undefined' || column_index === null) { return; }
    this.update_filter_visual(column_index, false);
    this.clear_temp_filter();
  }

  update_klass_filter(klass_data) {
    let current_klass_index = this.filters.klass.content.findIndex(function (v) {
      return v[0] == klass_data.id;
    });
    if (current_klass_index === -1) {
      this.filters.klass.content.push([klass_data.id, klass_data]);
    } else {
      this.filters.klass.content[current_klass_index] = [klass_data.id, klass_data];
    }
    this.filters.klass.content = this.sort_filter_with_parents(this.filters.klass.content);
  }

  remove_klass_filter(el_id, column_index) {
    this.filters.klass.content = this.filters.klass.content.filter(function (v) {
      return v[1].parent_id != el_id && v[0] != el_id;
    });
  }

  search_in_filter(column_index, value, el, evt) {
    if (window.cmd_pressed && evt.keyCode === 91){
      el.setSelectionRange(0, value.length);
      return true;
    }

    let self = this;
    let columnName = ["project", "channel_title", "title"];
    let filter_settings = this.get_filter_settings_by_index(column_index);
    let column = $(`.filter-wrapper[data-column-name="${filter_settings.column_name}"]`);
    let correct_value = this.normolize_text(value);
    let arr_in_search = filter_settings.content.filter(function (v) {
      return self.normolize_text(v[1]).indexOf(correct_value) !== -1;
    });

    column.find('.filter-el').attr('data-value-in-search', false);
    $.each(arr_in_search, function (i, filter_value) {
      let id = null;
      if(columnName.includes(filter_settings.column_name)) {
        id = filter_value[0];
      } else {
        id = textToValidHtmlID(filter_value[0]);
      }
      let list_el = $(`#${filter_settings.column_index}_${filter_settings.type}_${id}`).closest('.filter-el');
      list_el.attr('data-value-in-search', true);
    });

    let no_values_search_block = column.find('.filter-no-values-search-wrapper');
    if (arr_in_search.length === 0) {
      no_values_search_block.show();
    } else {
      no_values_search_block.hide();
    }

    return true;
  }

  clear_search(column_index) {
    let filter_settings = this.get_filter_settings_by_index(column_index);
    let column = $(`.filter-wrapper[data-column-name="${filter_settings.column_name}"]`);
    let input = column.find('.filter-search-wrapper .custom-input');
    if (input.length > 0) {
      input.val('');
    }
    // document.addEventListener('keydown', e => {
    //   if (e.keyCode === 13){
    //     e.preventDefault();
    //   }
    // });
    column.find('.filter-el').attr('data-value-in-search', true);
    column.find('.filter-no-values-search-wrapper').hide();
  }

  build_search_by_params() {
    
    let self = this;
    let dt_search_arr = this.columns.map((x) => null);
    let url_params = this.get_filters_from_ls() || {};
    let url_params_entries = Object.entries(url_params);
    if (url_params_entries.length !== 0) {
      $.each(this.columns, function (i, column_data) {
        let param_data = url_params[column_data.data];
        if (param_data && self.filters[column_data.data] && self.filters[column_data.data].type) {
          dt_search_arr[i] = {};
          dt_search_arr[i].sSearch = param_data;
        }
      });
    }
    
    return dt_search_arr;
  }

  normolize_text(text) {
    return text.replace(/\s/g, '').toLowerCase();
  }

}

export default DataTableFilter;
