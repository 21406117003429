window.modal_show = function(){
  // const top_position = $(window).scrollTop();
  $("body").css({"overflow" : "hidden"});
  $(".main-modal-wrapper").css("width", "");
  $(".area-modal-wrapper").show();
  let modal = $(".main-modal-wrapper");
  let custom_width = modal.find(".content-modal-wrapper").attr("data-custom-width-modal");
  custom_width ? modal.css("width", custom_width) : "";
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  let top = (height / 2 - modal.height() / 2);
  top = top > 10 ? top : 10;
  modal.css({"margin-top": top});
};

window.modal_hide = function(){
  const top_position = $(window).scrollTop();
  $("body").css({"overflow" : "auto", "height" : "auto"});
  $(window).scrollTop(top_position);
  let url = $('.content-modal-wrapper').data('hide-url');
  $(".area-modal-wrapper").removeClass("video-modal");
  $(".area-modal-wrapper").hide();
  $(".inner-content-wrapper").html("");
  $(".main-modal-wrapper").css("width", "");
  if($("#ui-id-1").length > 0) {
    $("#ui-id-1, .ui-helper-hidden-accessible").remove();
  }
  if (url) {
    window.history.pushState(null, null, url);
  }
};

window.word_form = function( num, word ){
  let cases = [2, 0, 1, 1, 1, 2];
  return word[ (num%100>4 && num%100<20) ? 2 : cases[(num%10<5)?num%10:5] ];
};

window.json_for_attr = function(obj){
  return JSON.stringify(obj).replace(/"/g, "'");
};

window.json_from_attr = function(string){
  return JSON.parse(string.replace(/'/g, '"'));
};

window.refreshDatatable = function(){
  if (typeof data_table_controller === 'undefined') {
    return false;
  }
  data_table_controller.datatable_obj.page.resetMore();
  data_table_controller.datatable_obj.ajax.reload();
};

window.error_show = function(error_data){
  toastr.error(error_data.join('\n'));
};

window.set_ls_table_filters = function(obj){
  localStorage.setItem('goodok_table_filters', JSON.stringify(obj));
};

window.getMeta = function(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
};

window.custom_dropdown_click = function(element){
  let el = $(element);
  let wrapper = el.parents("[data-type='dropdown']");
  wrapper.find("[data-type='current-option']").html(el.html());
  wrapper.find("[data-toggle='dropdown']").click();
}

export default function(){}