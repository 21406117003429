class Audioplayer {
  constructor() {
    if (Audioplayer.instance instanceof Audioplayer) {
      return Audioplayer.instance;
    }

    this.id = null;
    this.el = null;
    this.player = null;
    this.playerTimeline = null;
    this.current_played_audio = null;

    Audioplayer.instance = this;
  }

  duration_to_string(duration) {
    let s = duration % 60;
    let m = Math.floor(duration / 60) % 60;

    s = s < 10 ? "0" + s : s;
    m = m < 10 ? "0" + m : m;

    return m + ":" + s;
  }

  audioplayer_dropdown() {
    document
    .querySelector(".audio-speed-dropdown")
    .addEventListener("shown.bs.dropdown", (e) => {
      let dropdown = e.target;
      let dropdown_menu = dropdown.querySelector(".dropdown-menu");
      let dropdown_menu_bottom_pos =
      dropdown_menu.top +
      document.body.scrollTop +
      dropdown_menu.offsetHeight;
      dropdown.setAttribute(
        "data-dropup",
        dropdown_menu_bottom_pos >= max_bottom_pos
      );

    });
  }

  change_audio_speed(new_speed) {
    this.current_played_audio.playbackRate = Number(new_speed);
    this.player
      .querySelector('.audio-speed-el[data-current-speed="true"]')
      .setAttribute("data-current-speed", false);
    this.player
      .querySelector(`.audio-speed-el[data-value="${new_speed}"]`)
      .setAttribute("data-current-speed", true);
    this.player.querySelector(".dropdown").classList.remove("open");
  }

  playCdr(el, id) {
    if (this.id !== id && this.current_played_audio) {
      this.current_played_audio.pause();
      this.current_played_audio.currentTime = 0;

      this.player.classList.remove("clicked");
      this.player.classList.remove("player_");

      this.playerTimeline.style.display = "none";
      this.player.style.width = "auto";

      this.el.classList.add("paused");
      this.el.classList.add("default");

      this.el.classList.remove("playing");
      this.el.classList.remove("time-audio");
    }

    this.id = id;
    this.el = el;
    this.player = document.querySelector(`.player[data-id="${id}"]`);
    this.current_played_audio = document.querySelector(
      `audio[data-id="${id}"]`
    );

    this.onPlay(el, id);
  }

  onPlay(el, id) {
    this.playerTimeline = document.querySelector(
      `.player_timeline[data-id='${id}']`
    );
    let audio = document.querySelector(`audio[data-id="${id}"]`),
      current_position = document.querySelector(
        `[data-id="${id}"][data-type=played]`
      );
    let loadPosition = current_position.querySelector(".buffer-bar");

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }

    document.body.addEventListener("click", (e) => {
      if (!this.player.contains(e.target) && e.target !== this.player) {
        audio.pause();

        this.player.classList.remove("clicked");
        this.player.classList.remove("player_");

        this.playerTimeline.style.display = "none";
        this.player.style.width = "auto";

        el.classList.add("paused");
        el.classList.add("default");

        el.classList.remove("playing");
        el.classList.remove("time-audio");
      }
    });

    document.querySelector(`.player_timeline[data-id='${id}']`).addEventListener("click", (e) => {
      audio.currentTime = Math.floor(e.offsetX / (e.target.offsetWidth / parseInt(audio.duration)));
      e.stopPropagation();
    });

    audio.ontimeupdate = (e) => {
      let currentTime = parseInt(e.target.currentTime),
          progressBar = current_position.querySelector(".progress-bar"),
          totalTime = this.playerTimeline.querySelector(".total-time"),
          minutes = Math.floor(currentTime / 60),
          seconds = currentTime % 60;

      progressBar.style.width = `${(100 / e.target.duration) * e.target.currentTime}%`;
      totalTime.textContent = `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    };

    audio.onended = () => {
      this.player.classList.remove("clicked");
      this.player.classList.remove("player_");

      this.playerTimeline.style.display = "none";
      this.player.style.width = "auto";

      el.classList.add("paused");
      el.classList.add("default");

      el.classList.remove("playing");
      el.classList.remove("time-audio");
    };

    audio.onprogress = (e) => {
      if (e.target.readyState === 4) {
        let loaded = Math.floor(
          (current_position.offsetWidth / parseInt(e.target.duration)) *
            e.target.buffered.end(e.target.buffered.length - 1)
        );
        loadPosition.style.width = `${loaded}%`;
      }
    };

    audio.stalled = () => {
      alert("Данные не доступны");
    };

    audio.onplay = () => {
      el.querySelector(".call-play-icon").style.cssText =
        "border: none; border-left: 3px solid #fff; border-right: 3px solid #fff; border-color: #fff; height: 10px;";

      this.player.classList.add("clicked");
      this.player.classList.add("player_");

      var getClosest = function (elem, selector) {
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
          if ( elem.matches( selector ) ) return elem;
        }
        return null;
      };

      var tr_player = getClosest(this.player, "tr");
      this.player.style.width = tr_player.offsetWidth - (getClosest(this.player, "[data-column-name='call_record']")
                                .getBoundingClientRect().x - tr_player
                                .getBoundingClientRect().x) - 15 + "px";
      this.playerTimeline.classList.add("timeplay");
      this.playerTimeline.style.display = "inline-block";

      el.classList.remove("paused");
      el.classList.remove("default");

      el.classList.add("playing");
      el.classList.add("time-audio");
    };

    audio.onpause = () => {
      el.querySelector(".call-play-icon").style.cssText =
        "border: 5px solid transparent; border-width: 6px 0px 6px 7px; border-left-color: #fff; height: 11px;";
    };
  }
}

export default Audioplayer;
