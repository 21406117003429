export function textToValidHtmlID(id) {
    id = rus_to_latin(id);
    const unpermittedChars = id.match(/([^A-Za-z0-9[\]{}_:-])\s?/g);
    if (!unpermittedChars) { return id; }

    let replacedIndex = 0;
    let validHtmlID = id;
    unpermittedChars.forEach((char) => {
        validHtmlID = validHtmlID.replace(char, replacedIndex);
        replacedIndex += 1;
    });

    return validHtmlID;
}

function rus_to_latin(str) {
    let ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
    }, n_str = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');
    const size = str.length;
    for (let i = 0; i < size; ++i) {
        n_str.push(
            ru[ str[i] ]
            || ru[str[i].toLowerCase()] == undefined && str[i]
            || ru[str[i].toLowerCase()].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
        );
    }

    return n_str.join('');
}