class LandingTableDataset {
    constructor(visitorTown, listOfTowns) {
        this.town = visitorTown;
        this.towns = listOfTowns || [];
        this.totalRows = 30;
        this.dataSet = [];
        this.generateDataset();
    };

    get tableData() {
        return this.dataSet;
    };
    get klasses() {
        return [
            ['Лид', '#34A88C'],
            ['Спам', '#FF5170'],
            ['Непонятно', '#7B68EE'],
            ['Перекуп', '#E8BA14'],
            ['Покупатель', '#62B4FF']
        ];
    };

    calculateConversionNumber(phoneNumber) {
        return this.dataSet.filter(item => item.src === phoneNumber).length + 1;
    };

    getSrc(idx){
        const srcNumbers = [
            '74245361811',
            '76310088087',
            '72632370482',
            '78609208479'
        ];

        return idx % 5 === 0 ? srcNumbers[Math.floor(Math.random() * srcNumbers.length)] : `7${Math.floor(Math.random() * 10000000000)}`;
    };

    getDate(idx){
        return moment().subtract(2, 'weeks')
            .add(idx >= 14 ? 14 : idx, 'days')
            .hour(Math.floor(Math.random() * 24))
            .minute(Math.floor(Math.random() * idx <= 59 ? idx : 59));
    };
    
    updateTableData(el, column, dataColumn){
        let table = $('#landing-table-wrapper').DataTable();
        let data = table.row(el).data();
        data[column] = dataColumn;
        table.row(el).data(data).draw();
    }

    getAudio(idx){
        let status = "missed";
        const length = [
            '01:16',
            '01:16',
            '01:13',
            '00:12',
            '00:12',
            '01:03',
            '00:58',
            '00:10',
            '00:11',
            '00:12',
            '00:16',
            '00:11',
            '00:11',
            '00:13',
            '01:04',
            '00:55',
            '01:27',
            '01:20',
            '01:53',
            '01:37',
            '02:50',
            '01:03',
            '01:44',
            '02:34',
            '00:00',
            '00:00',
        ];
        if(length[idx] > '00:10'){
            status = "long";
        } else if (length[idx] <= '00:10') {
            status = "short";
        } else if (length[idx] == '00:00') {
            status = "reset";
        }
        return [status, idx, length[idx + 1]];
    };

    audioColumn(type, name, duration){
        let type_audio;

        type_audio = { 
          'long': 'call-long-audio', 
          'short': 'call-short-audio',
          'reset': 'call-reset-audio',
        }[type];

        if(duration){
            return `
                <div class="player player_call ${type_audio} exists" data-id="${name}">
                    <a role="button" class="play_cdr play-time" data-id="${name}" onclick="Audioplayer.playCdr(this, ${name})">
                      <audio preload="none" class="player_audio" data-id="${name}">
                        <source src="/landing_audio/${name}.mp3" type="audio/mpeg" codecs="mp3"></source>
                      </audio>
                      <span class="call-play-icon"></span><small class="current-time">${duration}</small>
                    </a>
                    <div class="player_timeline" data-id="${name}" style="display: none;">
                      <small class="total-time">${duration}</small>
                      <div class="load-main" data-id="${name}" data-type="played">
                        <div class="progress-bar"></div>
                        <div class="buffer-bar"></div>
                      </div>
                    </div>
                    <div class="dropdown audio-speed-dropdown dropdown-fixed-position" onclick="Audioplayer.audioplayer_dropdown()">
                      <button class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true"></button>
                      <div class="dropdown-menu">
                        <ul class="audio-speed-list">
                          <li class="audio-speed-el" data-current-speed="true" data-value="1" onclick="Audioplayer.change_audio_speed(1);">1</li>
                          <li class="audio-speed-el" data-current-speed="false" data-value="1.25" onclick="Audioplayer.change_audio_speed(1.25);">1.25</li>
                          <li class="audio-speed-el" data-current-speed="false" data-value="1.5" onclick="Audioplayer.change_audio_speed(1.5);">1.5</li>
                          <li class="audio-speed-el" data-current-speed="false" data-value="1.75" onclick="Audioplayer.change_audio_speed(1.75);">1.75</li>
                          <li class="audio-speed-el" data-current-speed="false" data-value="2" onclick="Audioplayer.change_audio_speed(2);">2</li>
                        </ul>
                      </div>
                  </div>
                  <a role="button" class="download_bttn" href="/landing_audio/${name}.mp3" download=""></a>
                </div>`;
        };
        return '<span class="call-audio-item call-not-answer-audio"><span class="call-not-answer-icon"></span></span>';
    };

    generateDataset() {
        const klasses = this.klasses;
        const projects = [
            {
                project: "Барбершоп",
                channel_title: [
                    'Direct',
                    'SEO',
                    'Флаеры',
                    'Наружка',
                    'Радио'
                ],
            },
            {
                project: "VW Passat",
                channel_title: [
                    'Авто.ру',
                    'Дром'
                ],
            }

        ];
        const comment = [
            'МВД',
            'Хотя скупать волосы Оо',
            'Прическа, борода',
            'Сертификат',
            'Не было времени, сказал перезвонит',
            'Прическа',
            'Ищет работу',
            'Оформление бороды',
            'Сертификат',
            'Запись отец и сын',
            'Стрижка на текущее',
            'непонятно что говорит',
            'Сертификат',
            'Стрижка',
            'Стрижка и оформление бороды',
            'Договорились перезвонить',
            'Запись, просил скидку',
            'тишина в трубке',
            'Блогер хотел бесплатно',
            'Ошиблись номером',
            'Астан, просит перегнать',
            'мутный астан',
            'Салон, гатовы вызять за 750',
            'Разборки, хотят двигатель',
            'Обмен на bmwe 318i e90',
            'Сергей, 950',
            'уже звонила',
            'Алина, 980',
            'Костя, хочет на СТО',
        ];
        for(let i = 0; i <= this.totalRows; i++) {
            let src = this.getSrc(i);
            let project = projects[Math.floor(Math.random() * projects.length)];
            this.dataSet.push(
                {   
                    "date": this.getDate(i),
                    "src": src,
                    "region": i % 2 === 0 ? this.town : this.towns[Math.floor(Math.random() * this.towns.length)],
                    "number": this.calculateConversionNumber(src),
                    "klass": klasses[Math.floor(Math.random() * klasses.length)],
                    "project": project.project,
                    "channel_title": project.channel_title[Math.floor(Math.random() * project.channel_title.length)],
                    "call_record": this.getAudio(i),
                    "comment": i <= comment.length-1 ? comment[i] : ''
                }
            );
        };

    };

    generateFiltersContent() {
        let filter = {
            // дописать также оставшиеся фильтры. Те, которые статика - их не надо из таблицы собирать, они известны и не меняются
            // статику добавляем в переменную в конце, после сбора остальных данных
            project: { label: 'Проект', type: "default", column_index: 1, content: new Set() },
            channel_title: {label: "Гудок-номер", type: "default", column_index: 2, content: new Set()},
            klass: {label: "Класс", type: "klass", column_index: 4, content: []},
            number: {label: "№", type: "default", column_index: 5, content: []},
            src: {label: "Номер клиента", type: "default", column_index: 6, content: new Set()},
            region: {label: "Регион клиента", type: "default", column_index: 7, content: new Set()},
            call_record:{label: "Запись", type: "default", column_index: 8, content: []}
            // фильтр с классами тоже статика, строим по this.klasses с указанием цвета - должен выглядеть также как в кабинете
        };
        this.dataSet.forEach((row) => {
            ["project", "channel_title", "src", "region"].forEach( column => filter[column].content.add(row[column]));
        });
        Object.keys(filter).forEach((column) => {
            filter[column].content = Array.from(filter[column].content).map(item => {
                if(column === 'src'){
                   return [item, `+${item.slice(0, 1)} ${item.slice(1, 4)} ${item.slice(4, 7)} ${item.slice(7, 9)} ${item.slice(9, 11)}`];
                }
                return [item, item];
            });
        });
        this.klasses.map((item, index) => {
            filter.klass.content.push([item[0], {title: item[0], color: item[1], parent_id: null, id: item[0]}])
        })
        filter.number.content = [
            ['uniq', 'Первый'],
            ['repeated', 'Остальные']
        ];
        filter.call_record.content = [
            ["missed", {title: "Без ответа", color: "#FFC0CC"}],
            ["short", {title: "<= 10 секунд", color: "#FFE4B0"}],
            ["long", {title: "> 10 секунд", color: "#C7F5E8"}],
            ["reset", {title: "Сброс по ВАТС", color: "#C4CDD7"}]
        ];
        return filter;
    }


    comments(el){
        let td = el.parentElement.parentElement;
        const text = el.querySelector('span');
        const textarea = el.querySelector('textarea');
        let width = text.offsetWidth;
        let height = text.offsetHeight;

        text.style.display = 'none';
        textarea.value = text.innerHTML;
        textarea.style.cssText = `display: flex; width: ${width}px; height: ${height}px;`;
        textarea.focus();

        textarea.addEventListener('keydown', (e) => {
            if(e.keyCode === 13 || e.keyCode === 9){
                e.preventDefault();
                text.style.display = 'block';
                textarea.style.display = 'none';
                this.updateTableData(td, 'comment', textarea.value);
            }
        });

        window.addEventListener('click', (e) => {
            if(!el.contains(e.target) && e.target !== el){
                text.style.display = 'block';
                textarea.style.display = 'none';
            }
        });
    }

    buildClasses(el, id) {
        let classesDropdown = '';
        for (let _class of this.klasses) {
            classesDropdown +=
                `<div onclick="LandingEvents.setClasses(this, '${_class[1]}', '${_class[0]}', ${id})">
                    <span style="background-color: ${_class[1]};">${_class[0]}</span>
                </div>`;
        }
        return classesDropdown;
    }

    classes(el, id){
        const text = el.querySelector('span');
        const dropdown = el.querySelector('.dropdown')
        text.classList.add('active');
        dropdown.innerHTML = this.buildClasses(el, id);
        data_table_controller.fixed_left_dropdown_content(el, dropdown);

        window.addEventListener('click', (e) => {
            if(!el.contains(e.target) && e.target !== el){
                text.classList.remove('active');
            }
        });
    }

    setClasses(el, color, title){
        let setClass = [title, color];
        let td = el.parentElement.parentElement.parentElement.parentElement;
        this.updateTableData(td, 'klass', setClass);
    }
}

export default LandingTableDataset;