import DataTableController from './data_table_controller';


class LandingDataTableController extends DataTableController {
    pageLength() {
        return 10;
    };

    tableInitComplete(api, settings) {
        super.tableInitComplete(api, settings);
        api.page.loadMore();
    };

    filterColumn(columnIndex, filterValue) {
        if(filterValue !== ''){
            let seriaLizedFilterValue = filterValue.join('|');
            this.datatable_obj.columns(columnIndex).search(seriaLizedFilterValue, true).draw();
        } else {
            this.datatable_obj.columns(columnIndex).search(['']).draw();
        }
    };

    rowCallback(row, data) {
        Object.keys(data).map((item, index) => {
            ['project', 'channel_title', 'number', 'region'].forEach((column) => {
                if(item === column)
                    $(`td:eq(${index})`, row).html(`<span class='cell-content common-text' ${column === 'project' ? `` : ''} data-column-name=${column}>${data[column]}</span>`); //data-tip="${/*data[column]*/}"
            });
            $(`td:eq(${index})`, row).attr("data-info-name", `${item}`);
            if(item === 'number'){
              $(`th:eq(${index})`).hide();
              $(`td:eq(${index})`, row).hide();
            }
            if(item === 'date'){
                $(`td:eq(${index})`, row).html(`<span class='cell-content common-text' data-column-name=${item}>${moment(data[item]).format('DD.MM.YY HH:MM')}</span>`);
            } else if(item === 'comment'){
                $(`td:eq(${index})`, row).html(
                    `   
                        <span class="cell-content common-text" data-column-name="${item}">
                            <span onclick="LandingEvents.comments(this)" class="comment-text">
                                <span ${data[item] === '' ? 'class="no-comment"' : ''}>${data[item] || 'Комментарий'}</span>
                                <textarea value="${data[item]}" placeholder="Комментарий..."></textarea>
                            </span>
                        </span>
                    `
                );
                var table_wr = $("#landing-table-wrapper tbody"),
                    comment_w = table_wr.prop("clientWidth") - 732 - LandingDataTableController.scrollbarTableWidth();
                $(`td:eq(${index})`, row).attr("style", "width: " + comment_w + "px !important;");
            } else if(item === 'klass'){
                $(`td:eq(${index})`, row).html(
                    `   
                    <span class="cell-content common-text" data-column-name="${item}">
                        <span onclick="LandingEvents.classes(this)" class="call-klass-item">
                            <span class="call-klass-item-text" style="background: ${data[item][1]};">${data[item][0]}</span>
                            <div class="dropdown"></div>
                        </span>
                    </span>
                    `
                );
            } else if(item === 'src'){
                 let number = `+${data[item].slice(0, 1)} ${data[item].slice(1, 4)} ${data[item].slice(4, 7)} ${data[item].slice(7, 9)} ${data[item].slice(9, 11)}`;
                $(`td:eq(${index})`, row).html(`<span class='cell-content common-text' data-column-name=${item}>${number}</span>`);
            } else if(item === 'call_record'){
                $(`td:eq(${index})`, row).html(
                    `<span class="cell-content common-text" data-column-name="${item}">`+
                        LandingEvents.audioColumn(data[item][0], data[item][1], data[item][2]) +
                    '</span>'
                );
            }
        })
    };
    headerCallback(thead, data) {
      var headers_arr = $(thead).find('th');

      $.each(headers_arr, function (index, header) {
        var column_name = columns[index].data;
        $(`th:eq(${index})`).attr("data-info-name", `${column_name}`);

        if (column_name === 'comment') {
          var table_wr = $("#landing-table-wrapper tbody"),
              comment_w = table_wr.prop("clientWidth") - 732 - LandingDataTableController.scrollbarTableWidth();
          $(`th:eq(${index})`).attr("style", "width: " + comment_w + "px !important;");
        }
      });
    };
    static scrollbarTableWidth() {
      var scroll_table = $("#landing-table-wrapper_wrapper .table-overflow-wrapper"),
          documentWidth = parseInt(scroll_table.prop("clientWidth")),
          windowsWidth = parseInt(scroll_table.innerWidth()),
          scrollbarTableWidth = windowsWidth - documentWidth;
      return scrollbarTableWidth;
    }

}

export default LandingDataTableController;  