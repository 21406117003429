class PromoCodeActivator {
    constructor(checkURL) {
        this.checkURL = checkURL;
        this.addEvents();
    }

    apply(code) {
        if (!code || code.length === 0) {
            return { error: 'required' }; // TODO: отрисовать ошибку на форме
        }

        let self = this;
        fetch(this.checkURL, {
            method: 'POST',
            body: JSON.stringify({code: code}),
            headers: {
                'X-CSRF-Token': getMeta('csrf-token'),
                'Content-type': 'application/json; charset=UTF-8'
            }
        }).then(
            response => response.json()
        ).then((data) => {
            self.handleResponce(data);
        });
    }

    handleResponce(result) {
        $('#enter_code_block').hide();

        if (result.error) {
            $(`#${result.error}`).show();
        } else if (result.amount) {
            $('#amount').html(`${result.amount}<span class="ruble-icon">i</span>`);
            $('#promo_owner_name').text(`${result.user}`);
            if (result.avatar) {
                $('#promo_owner_avatar').attr('src', `${result.avatar}`);
            }
            if (result.days) {
                $('#days').text(`${result.days}`);
            }
            $('.promo-title').hide();
            $('#title-success-promo').show();
            $('#success').show();
            $('#footer-block').show();
        }
    }

    addEvents() {
        let self = this;
        $('#submit_button').on("click", (event) => {
            this.apply($('#promo_code_value').val());
        });

        $('.reset_form').on('click', (event) => {
            $(event.target.parentElement).hide();
            $('#promo_code_value').val('');
            $('#enter_code_block').show();
        });
    }
}

export default PromoCodeActivator;